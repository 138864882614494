import React, { createContext, useState } from "react";

const defaultState = {};

const AppContext = createContext(defaultState);

const AppProvider = ({ children }) => {
  // TODO for the next big update: Merge all modals withing one component/state. Pass open: true/false, and modalType (HCP Acknowledgement, Exit Modal, etc) as props.
  const [showExitModal, setShowExitModal] = useState(false);
  const [showHcpModal, setShowHcpModal] = useState(false);
  const [exitUrl, setExitUrl] = useState("");
  const [customCopy, setCustomCopy] = useState(null);

  const openHcpModal = () => {
    setShowHcpModal(true);
  };

  const closeHcpModal = () => {
    setShowHcpModal(false);
  };

  const openExitModal = (event, url, customCopy) => {
    event.preventDefault();
    setExitUrl(url);
    setCustomCopy(customCopy);
    setShowExitModal(true);
  };

  const closeExitModal = () => {
    setShowExitModal(false);

    // bug fix: prevent the modal from closing before the exit URL is opened
    // without this, the exit url clears too fast, and the browser opens a tab with the same url instead of the exit one
    setTimeout(() => {
      setExitUrl("");
      setCustomCopy(null);
    }, 100);
  };

  const store = {
    showExitModal,
    showHcpModal,
    exitUrl,
    openExitModal,
    closeExitModal,
    openHcpModal,
    closeHcpModal,
    customCopy,
  };

  return <AppContext.Provider value={store}>{children}</AppContext.Provider>;
};

export default AppContext;

export { AppProvider };
